<template>
  <v-stepper-content :step="step">
    <v-card outlined class="mb-6" color="rgb(0,0,0,0)">
      <template v-if="apierror.status === 'error'">
        <div v-for="msg of apierror.messages" :key="msg">
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
      <v-card outlined color="rgb(0,0,0,0)">
        <div class="d-flex align-center mb-3 pl-3" style="height: 40px; background-color: #ebe9e8">
          <strong>注文商品</strong>
        </div>
        <v-card width="100%" outlined color="rgba(0,0,0,0)">
          <v-row style="font-weight: bold; color: black">
            <v-col cols="12" md="4" lg="3" sm="6" class="d-flex justify-center">
              <div style="width: 280px" class="d-flex align-center">
                <v-img :src="setForm.image" class="d-flex align-center"></v-img>
              </div>
            </v-col>
            <v-col cols="12" md="8" lg="9" sm="6" class="mt-2">
              <div style="font-size: 20px; font-weight: bold">
                <span id="setName">{{ setForm.set_name }}</span>
              </div>
              <div class="d-flex align-center">
                <span class="text">数量：</span>
                <div class="d-flex align-center">
                  <v-btn
                    depressed
                    color="#ff6e40"
                    min-width="28%"
                    max-width="28%"
                    max-height="26px"
                    min-height="26px"
                    @click="decre"
                  >
                    <v-icon color="#fff">
                      mdi-minus
                    </v-icon>
                  </v-btn>
                  <div
                    class="text-center"
                    style="
                      border: 1px solid #2e2e22;
                      border-radius: 5px;
                      width: 33%;
                      max-height: 26px;
                      min-height: 26px;
                      min-width: 50px;
                      padding-top: 1px;
                    "
                  >
                    {{ orderQty }}
                  </div>
                  <v-btn
                    depressed
                    color="#ff6e40"
                    min-width="28%"
                    max-width="28%"
                    max-height="26px"
                    min-height="26px"
                    @click="incre"
                  >
                    <v-icon color="#fff">
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </div>
              </div>
              <div class="text d-flex align-center">
                <span>単価：</span>
                <div class="d-flex align-center justify-end" :style="`min-width:${!$vuetify.breakpoint.smAndUp ? '45%' : '20%'}`">
                  <span>
                    ￥{{ setPrice ? setPrice.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '0' }}
                  </span>
                </div>
              </div>
              <div class="text d-flex align-center">
                <span>送料：</span>
                <div class="d-flex align-center justify-end" :style="`min-width:${!$vuetify.breakpoint.smAndUp ? '45%' : '20%'}`">
                  <span>
                    ￥{{ shipping ? shipping.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '0' }}
                  </span>
                </div>
              </div>
              <div class="text d-flex align-center">
                <span>合計：</span>
                <div class="d-flex align-center justify-end" :style="`min-width:${!$vuetify.breakpoint.smAndUp ? '45%' : '20%'}`">
                  <span>
                    ￥{{ sumPrice }}
                  </span>
                </div>
              </div>
            </v-col>
            <!-- <v-col cols="12" md="4" lg="4" sm="12" class="mt-1"> </v-col> -->
          </v-row>
          <v-divider class="my-6"></v-divider>
        </v-card>
        <div class="d-flex align-center mb-3 pl-3" style="height: 40px; background-color: #ebe9e8">
          <strong>送付先</strong>
        </div>
        <div style="line-height: 28px; font-size: 15px" class="px-3">
          <div v-if="info.mbr_address2 !== ''">
            <span style="font-size: 16px">
              <strong>{{ info.mbr_sei }} {{ info.mbr_mei }}</strong>
            </span>
            <br />
            <span>{{ info.mbr_zip }}</span>
            <br />
            <span>{{ info.mbr_address1 }}</span>
            <br />
            <span>{{ info.mbr_address2 }}</span>
            <br />
            <span>{{ info.mbr_mobile }}</span>
            <br />
          </div>
          <div v-if="info.mbr_address2 === ''">
            <span style="font-size: 16px">
              <strong>{{ info.mbr_sei }} {{ info.mbr_mei }}</strong>
            </span>
            <br />
            <span>{{ info.mbr_zip }}</span>
            <br />
            <span>{{ info.mbr_address1 }}</span>
            <br />
            <span>{{ info.mbr_mobile }}</span>
            <br />
          </div>
        </div>
        <div class="mt-10">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="3" class="d-flex justify-end">
              <v-btn outlined width="100%" @click="prevPage">
                <span>戻る</span>
              </v-btn>
            </v-col>
            <v-col cols="6" sm="3" class="d-flex justify-end">
              <v-btn color="#ff6e40" width="100%" depressed :loading="submiting" @click="pay">
                <span style="color: #fff">購入する</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-card>
    <v-dialog v-model="dialogOK" persistent width="500">
      <v-card height="220px" class="d-flex align-center justify-center">
        <div class="px-3">
          <div class="mt-4" style="text-align: center; font-weight: bold; font-size: 20px">
            <span>ご購入ありがとうございます。</span>
          </div>
          <div class="d-flex justify-center mt-8">
            <v-btn color="#ff6e40" width="80px" depressed @click="backOK">
              <span style="color: #fff">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNG" persistent width="500">
      <v-card height="220px" class="d-flex align-center justify-center">
        <div class="px-3">
          <template v-if="apierror.status == 'error'">
            <div v-for="(msg, index) of apierror.messages" :key="index">
              <v-row class="ml-3 mb-3 ma-3">
                <span style="color: red; font-size: 18px">{{ msg }}</span>
              </v-row>
            </div>
          </template>
          <div class="d-flex justify-center mt-8">
            <v-btn color="#ff6e40" width="80px" depressed @click="backNG">
              <span style="color: #fff">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-stepper-content>
</template>
<script>
import {
  mapState, mapActions,
} from 'vuex'

export default {
  props: ['step', 'info', 'cardToken', 'orderSet', 'shipping'],
  data: () => ({
    dialogOK: false,
    dialogNG: false,
    submiting: false,
    form: '',
    set: '',
    setPrice: '',
    message: '',
    setForm: '',
    orderQty: 1,
    order: {
      pref_code: '',
      details: [
        {
          product_code: '',
          order_qty: 1,
        },
      ],
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),
  computed: {
    ...mapState('setStore', ['selectedSet']),
    ...mapState('shippingFee', ['ShippingFee']),

    sumPrice() {
      const sum = this.setPrice * this.orderQty + this.shipping

      return sum.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
    },
  },
  watch: {
    orderQty() {
      this.getOrderShipping()
    },
  },
  created() {
    this.setPrice = this.selectedSet.set_tip
    this.loadData()
  },
  methods: {
    ...mapActions('trailSetStore', ['createTrailSet']),
    ...mapActions('setStore', ['loadSet']),
    ...mapActions('shippingFee', ['loadShippingFee']),

    loadData() {
      this.loadSet(this.orderSet.set_id).then(() => {
        console.log('check set', this.selectedSet)
        this.setForm = {
          ...this.selectedSet,
        }
        this.setForm.image = `${this.selectedSet.imgUrl_prefix}/${this.selectedSet.set_images
          .replaceAll('"', '')
          .split(',')[0]
          .replace(/^\//g, '')}`
        console.log('setForm.image', this.setForm.image)
      })
    },
    prevPage() {
      this.$emit('prevPage', this.step)
      this.apierror = {
        code: '',
        status: '',
        messages: [],
      }
    },
    getOrderShipping() {
      console.log('this.info', this.info)
      this.order.pref_code = this.info.prefcode
      this.order.details[0].product_code = this.info.details[0].product_code
      this.order.details[0].order_qty = this.orderQty
      console.log('this.order', this.order)
      this.loadShippingFee(this.order).then(() => {
        this.$emit('getShippingFee', this.ShippingFee.data.total)
      })
    },
    incre() {
      if (this.orderQty < 20) {
        this.orderQty += 1
      }
    },
    decre() {
      if (this.orderQty > 1) {
        this.orderQty -= 1
      }
    },
    pay() {
      this.form = this.info
      this.form.details[0].order_qty = this.orderQty
      this.form.cardToken = this.cardToken
      console.log('form', this.form)
      this.submiting = true
      this.createTrailSet(this.form)
        .then(() => {
          this.dialogOK = true
          this.apierror = {
            code: '',
            status: '',
            messages: [],
          }
        })
        .catch(error => {
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message

          // this.dialogNG = true
        })
        .finally(() => {
          this.submiting = false
        })
    },
    backOK() {
      this.dialogOK = false
      this.$router.push('/')
    },
    backNG() {
      this.dialogNG = false
    },
  },
}
</script>

<style scoped>
.text {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
}
</style>

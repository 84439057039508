<template>
  <div>
    <div style="font-size: 18px">
      <strong class="d-flex justify-center">お届け先情報</strong>
    </div>
    <div style="height: 10px"></div>
    <template v-if="apierror.status === 'error'">
      <div v-for="msg of apierror.messages" :key="msg">
        <v-row class="ml-6 mb-3 ma-3">
          <span style="color: red">* {{ msg }} </span>
        </v-row>
      </div>
    </template>
    <div style="height: 10px"></div>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" md="3">
        <div>
          <span>お名前</span>
          <span class="ml-1 pr-1" style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px">
            必須</span
          >
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <div class="d-flex">
          <v-text-field
            v-model="$v.form.mbr_sei.$model"
            :error-messages="seiErrors"
            placeholder="姓"
            hide-details="auto"
            outlined
            dense
            color="#ff6e40"
          ></v-text-field>
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="$v.form.mbr_mei.$model"
          :error-messages="meiErrors"
          placeholder="名"
          hide-details="auto"
          outlined
          dense
          color="#ff6e40"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" md="3">
        <div>
          <span>フリガナ</span>
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="$v.form.mbr_sei_kana.$model"
          :error-messages="seiKanaErrors"
          placeholder="セイ"
          hide-details="auto"
          outlined
          dense
          color="#ff6e40"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <div class="d-flex">
          <v-text-field
            v-model="$v.form.mbr_mei_kana.$model"
            :error-messages="meiKanaErrors"
            placeholder="メイ"
            hide-details="auto"
            outlined
            dense
            color="#ff6e40"
          ></v-text-field>
        </div>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" md="3">
        <div>
          <span>郵便番号</span>
          <span class="ml-1 pr-1" style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px">
            必須</span
          >
        </div>
        <div style="font-size: 12px">半角-ハイフンなし</div>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="$v.form.mbr_zip.$model"
          :error-messages="zipErrors"
          outlined
          dense
          color="#ff6e40"
          placeholder="1000001"
          hide-details="auto"
          :loading="loading"
          @input="handleZipInput"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" class="d-flex justify-start"> </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" md="3">
        <div>
          <span>住所</span>
          <span class="ml-1 pr-1" style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px">
            必須</span
          >
        </div>
      </v-col>
      <v-col cols="12" sm="8">
        <v-text-field
          v-model="$v.form.mbr_address1.$model"
          :error-messages="mbr_address1Errors"
          placeholder="市区町村番号(例：東京都千代田区大手町1-2-3)"
          hide-details="auto"
          outlined
          dense
          color="#ff6e40"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" md="3"></v-col>
      <v-col cols="12" sm="8">
        <v-text-field
          v-model="$v.form.mbr_address2.$model"
          :error-messages="mbr_address2Errors"
          placeholder="建物名・部屋番号"
          hide-details="auto"
          outlined
          dense
          color="#ff6e40"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" md="3">
        <div>
          <span>電話番号</span>
          <span class="ml-1 pr-1" style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px">
            必須</span
          >
        </div>
        <div style="font-size: 12px">半角-ハイフンなし</div>
      </v-col>
      <v-col cols="12" sm="8">
        <v-text-field
          v-model="$v.form.mbr_mobile.$model"
          :error-messages="mobileErrors"
          placeholder="09012345678"
          hide-details="auto"
          outlined
          dense
          color="#ff6e40"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" md="3">
        <div>
          <span>メールアドレス</span>
          <span class="ml-1 pr-1" style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px">
            必須</span
          >
        </div>
      </v-col>
      <v-col cols="12" sm="8">
        <v-text-field
          v-model="$v.mbr_email.$model"
          :error-messages="emailErrors"
          placeholder="メールアドレス"
          hide-details="auto"
          outlined
          dense
          color="#ff6e40"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" md="3">
        <div>
          <span>メール確認</span>
          <span class="ml-1 pr-1" style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px">
            必須</span
          >
        </div>
      </v-col>
      <v-col cols="12" sm="8">
        <v-text-field
          v-model="$v.confirm_mail.$model"
          placeholder="メールアドレス（確認用）"
          :error-messages="confirmMailErrors"
          hide-details="auto"
          outlined
          dense
          color="#ff6e40"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row class="my-6">
      <v-spacer></v-spacer>
      <v-col cols="6" sm="3" class="d-flex justify-end">
        <v-btn outlined width="100%" @click="closeDialog">
          <span>戻る</span>
        </v-btn>
      </v-col>
      <v-col cols="6" sm="3" class="d-flex justify-end">
        <v-btn color="#ff6e40" width="100%" depressed :loading="submitStatus" @click="nextPage">
          <span style="color: #fff">次へ</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { required, maxLength, sameAs, email } from 'vuelidate/lib/validators'
import { helpers } from '@vuelidate/validators'
import { mapState, mapActions } from 'vuex'

const allowedKana = helpers.regex(/^[ァ-ヶー]*$/)
const validMobile = helpers.regex(/^0[0-9]{9,10}$/)
const validZip = helpers.regex(/^[0-9]{3}[-]{0,1}[0-9]{4}$/)

export default {
  props: ['showDialog', 'el', 'info', 'shipping', 'orderSet'],
  data: () => ({
    submitStatus: false,
    mbr_email: '',
    confirm_mail: '',
    loading: false,
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    form: {
      mbr_sei: '',
      mbr_mei: '',
      mbr_sei_kana: '',
      mbr_mei_kana: '',
      mbr_zip: '',
      prefcode: '',
      mbr_address1: '',
      mbr_address2: '',
      mbr_mobile: '',
      mbr_email: '',
      details: [
        {
          product_code: 'SET-TRIAL001',
          order_qty: 1,
        },
      ],
    },
    order: {
      pref_code: '',
      details: [
        {
          product_code: 'SET-TRIAL001',
          order_qty: 1,
        },
      ],
    },
  }),
  validations: {
    form: {
      mbr_sei: {
        required,
        maxLength: maxLength(10),
      },
      mbr_mei: {
        required,
        maxLength: maxLength(10),
      },
      mbr_sei_kana: {
        maxLength: maxLength(10),
        allowedKana,
      },
      mbr_mei_kana: {
        maxLength: maxLength(10),
        allowedKana,
      },
      mbr_mobile: {
        required,
        maxLength: maxLength(13),
        validMobile,
      },
      mbr_zip: {
        required,
        validZip,
      },

      mbr_address1: {
        required,
        maxLength: maxLength(100),
      },
      mbr_address2: {
        maxLength: maxLength(100),
      },
    },

    mbr_email: {
      required,
      email,
    },
    confirm_mail: {
      required,
      email,
      sameAs: sameAs('mbr_email'),
    },
  },
  computed: {
    ...mapState('shippingFee', ['ShippingFee']),

    seiErrors() {
      const errors = []
      if (!this.$v.form.mbr_sei.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.mbr_sei.maxLength && errors.push('最大10文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.mbr_sei.required && errors.push('必須項目')

      return errors
    },
    meiErrors() {
      const errors = []
      if (!this.$v.form.mbr_mei.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.mbr_mei.maxLength && errors.push('最大10文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.mbr_mei.required && errors.push('必須項目')

      return errors
    },
    seiKanaErrors() {
      const errors = []
      if (!this.$v.form.mbr_sei_kana.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.mbr_sei_kana.maxLength && errors.push('最大10文字までです！')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.mbr_sei_kana.allowedKana && errors.push('全角カナを入力してください。')

      return errors
    },
    meiKanaErrors() {
      const errors = []
      if (!this.$v.form.mbr_mei_kana.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.mbr_mei_kana.maxLength && errors.push('最大10文字までです！')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.mbr_mei_kana.allowedKana && errors.push('全角カナを入力してください。')

      return errors
    },

    zipErrors() {
      const errors = []
      if (!this.$v.form.mbr_zip.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.mbr_zip.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.mbr_zip.validZip && errors.push('数字7桁、半角―ハイフンなしで入力して下さい。')

      return errors
    },

    mobileErrors() {
      const errors = []
      if (!this.$v.form.mbr_mobile.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.mbr_mobile.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.mbr_mobile.validMobile && errors.push('0から始まる10桁または11桁の番号を入力して下さい。')

      return errors
    },
    mbr_address1Errors() {
      const errors = []
      if (!this.$v.form.mbr_address1.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.mbr_address1.maxLength && errors.push('最大100文字までです。')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.mbr_address1.required && errors.push('必須項目')

      return errors
    },
    mbr_address2Errors() {
      const errors = []
      if (!this.$v.form.mbr_address2.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.mbr_address2.maxLength && errors.push('最大100文字までです。')

      return errors
    },

    emailErrors() {
      const errors = []
      if (!this.$v.mbr_email.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.mbr_email.email && errors.push('メールアドレスを入力してください。')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.mbr_email.required && errors.push('必須項目')

      return errors
    },

    confirmMailErrors() {
      const errors = []
      if (!this.$v.confirm_mail.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.confirm_mail.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.confirm_mail.sameAs && errors.push('上記「メールアドレス」と一致していません。')

      return errors
    },
  },
  methods: {
    // ...mapActions('memberStore', ['createMember']),
    ...mapActions('shippingFee', ['loadShippingFee']),
    handleZipInput(val) {
      if (val.length === 7) {
        this.getAddress()
      }
    },
    getAddress() {
      const api = 'https://zipcloud.ibsnet.co.jp/api/search?zipcode='
      const url = api + this.form.mbr_zip
      this.clearZipError()
      this.form.prefcode = ''
      this.form.mbr_address1 = ''

      // const str = JSON.stringify(url)
      this.loading = true
      fetch(url)
        .then(response => response.json())
        .then(data => {
          if (data.status === 400) {
            // エラー時
            this.setZipError()
            console.error('error1', this.error)
          } else if (data.results === null) {
            this.setZipError()
            console.error('error2', this.error)
          } else {
            this.form.prefcode = data.results[0].prefcode
            this.form.mbr_address1 = data.results[0].address1 + data.results[0].address2 + data.results[0].address3
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    setZipError() {
      this.apierror.messages = ['正しい郵便番号を入力してください']
      this.apierror.status = 'error'
      this.apierror.code = 'zip'
    },
    clearZipError() {
      this.apierror.messages = ''
      this.apierror.status = ''
      this.apierror.code = ''
    },
    clearform() {
      this.form = {
        mbr_sei: '',
        mbr_mei: '',
        mbr_sei_kana: '',
        mbr_mei_kana: '',
        mbr_zip: '',
        prefcode: '',
        mbr_address1: '',
        mbr_address2: '',
        mbr_mobile: '',
        mbr_email: '',
      }
      this.mbr_email = ''
      this.confirm_mail = ''
    },

    nextPage() {
      this.$v.$touch()
      if (this.apierror.code === 'zip') {
        return
      }
      if (!this.$v.$invalid) {
        this.submitStatus = true
        this.form.mbr_email = this.mbr_email
        this.form.details = this.orderSet.details
        this.order.details = this.orderSet.details
        this.order.pref_code = this.form.prefcode
        console.log('this.order', this.order)
        this.loadShippingFee(this.order)
          .then(() => {
            this.$emit('getShippingFee', this.ShippingFee.data.total)
            console.log('Address ShippingFee', this.ShippingFee)
            const input = JSON.parse(JSON.stringify(this.form))
            this.$emit('upinfo', input)
            this.$emit('nextPage', 2)
          })
          .catch(error => {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          })
          .finally(() => {
            this.submitStatus = false
          })
      }
    },

    closeDialog() {
      this.$emit('closeDialog')
    },
  },
}
</script>

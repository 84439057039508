<template>
  <v-app>
    <v-sheet class="px-4" min-width="410px">
      <div>
        <div v-if="$vuetify.breakpoint.smAndUp">
          <v-img width="100%" position="relative" :src="setForm.images" />
          <v-img
            v-if="showComingSoon(setForm)"
            style="width: 100%;position: absolute; top: 0; left: 0"
            src="@/assets/images/logos/BimiStock-ComingsoonMark.png"
            contain
          />
        </div>
        <div v-if="!$vuetify.breakpoint.smAndUp">
          <v-img
            style="width: 100%; background-position: center; position: relative"
            :src="setForm.images"
          />
          <v-img
            v-if="showComingSoon(setForm)"
            style="width: 100%;position: absolute; top: 0; left: 0"
            src="@/assets/images/logos/BimiStock-ComingsoonMark.png"
            contain
          />
        </div>
        <div class="mb-12 pb-3" style="position: relative">
          <div
            v-if="$vuetify.breakpoint.mdAndUp"
            style="
              margin-top: 25px;
              line-height: 80px;
              text-align: center;
              font-family: 'Noto Sans JP', sans-serif;
              font-style: normal;
              font-weight: 900;
              font-size: 55px;
              color: black;
              white-space: pre-line;
            "
          >
            <span>{{ setForm.set_name }}</span>
            <br />
            <span class="ml-6" style="font-size: 50px">{{ setForm.set_tip.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') }}円（送料・税込み）</span>
          </div>
          <div
            v-if="!$vuetify.breakpoint.mdAndUp"
            style="
              margin-top: 25px;
              line-height: 50px;
              text-align: center;
              font-family: 'Noto Sans JP', sans-serif;
              font-style: normal;
              font-weight: 900;
              font-size: 30px;
              color: black;
              white-space: pre-line;
            "
          >
            <span>{{ setForm.set_name }}</span>
            <br />
            <span class="ml-6" style="font-size: 25px">{{ setForm.set_tip.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') }}円（送料・税込み）</span>
          </div>
          <div
            style="
              /* margin-top: 5%; */
              font-family: 'Noto Sans JP', sans-serif;
              font-weight: bold;
              color: #000;
              text-align: center;
              font-size: 35px;
            "
            class="mt-3"
          >
            <span>販売期間: </span>
            <span>{{ setForm.set_ssd }}〜{{ setForm.set_sed }}</span>
          </div>
          <div style="margin: 25px 5% 0; font-family: 'Noto Sans JP', sans-serif; font-weight: bold">
            <div v-html="setForm.set_overview"></div>
          </div>
          <div v-if="!$vuetify.breakpoint.smAndUp">
            <v-row class="mt-6 mb-12" no-gutter>
              <v-spacer></v-spacer>
              <v-col v-for="(item, index) in mobileList" :key="index" cols="6" class="pa-5 pointer">
                <v-img
                  alt="BimiStock（ビミストック）は全国の美味しい保存食を3か月毎お届けする普段使いの防災食定期便です。"
                  :src="item.image"
                  @click="openForm(item.id)"
                ></v-img>
                <div class="text" style="white-space: pre-line">
                  <span>{{ item.item_name }}</span>
                </div>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <div
              v-if="!showComingSoon(setForm)"
              style="margin: auto; width: 342px; height: 120px"
              class="d-flex justify-center mt-6"
            >
              <v-btn
                depressed
                rounded
                style="background-color: #ff6e40; width: 60%; min-height: 80px"
                @click="openDialog"
              >
                <div
                  style="
                    color: #fff;
                    line-height: 37px;
                    font-family: 'Noto Sans JP', sans-serif;
                    font-style: normal;
                    font-weight: 900;
                    font-size: 20px;
                  "
                >
                  <span>購入する</span>
                </div>
              </v-btn>
              <!-- <DialogPay :show-dialog="showDialog" @closeDialog="closeDialog" /> -->
            </div>
            <!-- <div v-else style="text-align: center">
            <img src="@/assets/images/logos/comingSoon.jpg" style="fit-object: contain" width="50%" />
          </div> -->
          </div>
          <div
            v-if="$vuetify.breakpoint.smOnly"
            style="
              position: absolute;
              width: 650px;
              height: 550px;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              text-align: center;
            "
          >
            <span
              style="
                opacity: 0.08;
                line-height: 150px;
                white-space: nowrap;
                font-family: Helvetica;
                font-weight: bold;
                font-size: 100px;
                color: black;
              "
            >Delicious<br />Preservative<br />Foods</span>
          </div>
          <div
            v-if="$vuetify.breakpoint.mdOnly"
            style="
              position: absolute;
              width: 1000px;
              height: 700px;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              text-align: center;
            "
          >
            <span
              style="
                opacity: 0.08;
                line-height: 250px;
                white-space: nowrap;
                font-family: Helvetica;
                font-weight: bold;
                font-size: 150px;
                color: black;
              "
            >Delicious<br />Preservative<br />Foods</span>
          </div>
          <div
            v-if="$vuetify.breakpoint.lgAndUp"
            style="
              position: absolute;
              width: 1366px;
              height: 837px;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              text-align: center;
            "
          >
            <span
              style="
                opacity: 0.08;
                line-height: 300px;
                white-space: nowrap;
                font-family: Helvetica;
                font-weight: bold;
                font-size: 237px;
                color: black;
              "
            >Delicious<br />Preservative<br />Foods</span>
          </div>
          <div
            v-if="$vuetify.breakpoint.smAndUp"
            class="mt-12"
            style="max-width: 1366px; margin: auto; margin-top: -30px"
          >
            <v-row v-for="(line, index) in setList" :key="index">
              <v-spacer></v-spacer>
              <v-col v-for="(item, index) in line" :key="index" cols="6" md="3" class="pa-5 pointer">
                <v-img
                  alt="BimiStock（ビミストック）は全国の美味しい保存食を3か月毎お届けする普段使いの防災食定期便です。"
                  :src="item.image"
                  @click="openForm(item.id)"
                ></v-img>
                <div class="text" style="white-space: pre-line">
                  <span>{{ item.item_name }}</span>
                </div>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <div v-if="!showComingSoon(setForm)" class="d-flex justify-center my-12">
              <v-btn
                depressed
                rounded
                style="background-color: #ff6e40; width: 300px; min-height: 120px"
                @click="openDialog"
              >
                <div
                  style="
                    color: #fff;
                    line-height: 47px;
                    font-family: 'Noto Sans JP', sans-serif;
                    font-style: normal;
                    font-weight: 900;
                    font-size: 35px;
                  "
                >
                  <span>購入する</span>
                </div>
              </v-btn>
            </div>
            <!-- <div v-else style="text-align: center">
              <img src="@/assets/images/logos/comingSoon.jpg" style="fit-object: contain" width="50%" />
            </div> -->
          </div>
        </div>
      </div>
      <v-dialog v-model="dialog" class="previewDialog" :height="dialogHeight" scrollable max-width="900px">
        <v-card tile class="pt-6">
          <Overlays :is-absolute="true" :is-show="absoluteOverlayStatus"></Overlays>
          <v-card class="overflow-y-auto px-5" height="100%" tile flat>
            <v-container class="pa-5">
              <v-row no-gutters>
                <v-col class="pa-1" cols="12" md="6">
                  <img id="mainImg" :class="mainImgClass" :src="mainImg" @load="loadImg" />
                </v-col>
                <v-col class="rightArea d-flex align-start flex-column pa-1" cols="12" md="6">
                  <span id="titleArea" :class="`${titleAreaClass} mb-3`">{{ item.item_name }}</span>
                  <!-- <div :style="`height:${overviewHeight}px !important`" class="itemOversize" v-html="ItemOverview"></div> -->
                  <div
                    :style="`height:${overviewHeight}px !important;text-align:center;width:${overviewMaxWidth}px`"
                    class="itemOverview"
                    v-html="item.item_overview"
                  ></div>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="pa-1" cols="12">
                  <v-card class="mt-10" flat tile>
                    <div style="`font-size:${descFontSize}%;`" class="desc" v-html="item.item_desc"></div>
                  </v-card>
                </v-col>
              </v-row>
              <div
                style="
                  color: #ff6e40;
                  line-height: 35px;
                  font-family: 'Noto Sans JP', sans-serif;
                  font-style: normal;
                  font-weight: 900;
                  font-size: 15px;
                  cursor: pointer;
                "
                class="mb-12"
                @click="closeForm"
              >
                <span> ▶︎一覧に戻る </span>
              </div>
            </v-container>
          </v-card>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogSet" width="500px" persistent>
        <v-card height="180px" class="d-flex align-center justify-center">
          <div>
            <div class="mt-8" style="text-align: center; font-weight: bold; font-size: 20px">
              <span> お試しセットはお一人様一回限りです。 </span>
            </div>
            <div class="d-flex justify-center mt-8">
              <v-btn color="#ff6e40" width="80px" depressed @click="dialogSet = false">
                <span style="color: #fff">OK</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="Tip" width="500px" persistent>
        <v-card height="180px" class="d-flex align-center justify-center">
          <div>
            <div class="mt-8" style="text-align: center; font-weight: bold; font-size: 20px">
              <span> セットは一回２０個まで注文可能です。 </span>
            </div>
            <div class="d-flex justify-center mt-8">
              <v-btn color="#ff6e40" width="80px" depressed @click="Tip = false">
                <span style="color: #fff">OK</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
      <DialogPay :show-dialog="showDialog" :order-set="orderSet" @closeDialog="closeDialog" />
    </v-sheet>
  </v-app>
</template>

<script>
import {
  mdiCart, mdiClose,
} from '@mdi/js'
import {
  mapMutations, mapActions, mapState,
} from 'vuex'
import Overlays from '@/components/Overlays.vue'
import DialogPay from './components/Stepper.vue'

export default {
  components: {
    Overlays,
    DialogPay,
  },
  data: () => ({
    icons: {
      mdiCart,
      mdiClose,
    },
    orderSet: {
      details: [],
    },
    Tip: false,
    dialogSet: false,
    showDialog: false,
    size: 0,
    imgHeight: '',
    overviewHeight: '',
    overviewMaxWidth: '',
    dialog: false,
    images: [],
    imgExist: false,
    mainImg: '',
    mainImgClass: '',
    titleAreaClass: '',
    titleFontSize: '',
    item: {
      description: {
        item_desc: '',
      },
      overview: {
        item_overview: '',
      },
    },
    List: [],
    mobileList: [],
    setList: [],

    selectedImg: '',
    Form: {
      id: '',
      load: '',
      name: '',
      price_normal: '',
      count: 0,
    },
    setForm: {
      set_code: '',
      set_name: '',
      set_images: '',
      set_tip: '',
    },
  }),
  computed: {
    ...mapState('cartStore', ['number', 'count', 'cartList']),
    ...mapState('setStore', ['selectedSet', 'setItems']),
    ...mapState('app', ['absoluteOverlayStatus']),
    ...mapState('itemStore', ['selectedItem', 'itemList']),
    ...mapState('auth', ['isLoggedIn']),
    showComingSoon() {
      return function (set) {
        console.log('showComingSoon', set)

        return Date.now() < Date.parse(set.set_ssd)
      }
    },

    descFontSize() {
      const device = this.$vuetify.breakpoint.name
      if (device === 'xs') return 75
      if (device === 'sm') return 100
      if (device === 'md') return 125
      if (device === 'lg') return 125

      return 125
    },
    dialogHeight() {
      return document.documentElement.clientHeight * 0.9
    },
    Itemdescription() {
      let description = ''
      if (!this.item.description || !this.item.description.item_desc) {
        description = ''
      }
      description = this.item.description.item_desc

      return description
    },
    ItemOverview() {
      let overview = ''
      if (!this.item.overview || !this.item.overview.item_overview) {
        overview = ''
      }
      overview = this.item.overview.item_overview

      return overview
    },
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.clearDialogData()
      }
    },
  },
  created() {
    this.loadData()
  },
  mounted() {
    window.addEventListener('resize', this.resizeImg)
    window.addEventListener('load', this.getSize())
    window.addEventListener('resize', this.getSize)
  },
  methods: {
    ...mapMutations('cartStore', ['increNavCount', 'addProduct']),
    ...mapActions('setStore', ['loadSet', 'loadSetItems']),
    ...mapMutations('app', ['setAbsoluteOverlayStatus', 'setOverlayStatus']),
    ...mapActions('itemStore', ['loadItem', 'loadItemList']),

    openDialog() {
      if (this.isLoggedIn === false) {
        const obj = {
        }
        obj.product_code = this.setForm.set_code
        obj.order_qty = 1
        this.orderSet.set_id = this.setForm.set_id
        this.orderSet.details.push(obj)
        this.showDialog = true
      }
      if (this.isLoggedIn === true) {
        this.addToCart()
      }
    },
    closeDialog() {
      this.showDialog = false
    },

    getSize() {
      if (
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
        )
      ) {
        this.size = 1
      }
    },

    openForm(value) {
      this.dialog = true
      this.mainImgClass = `mianImg${this.id}`
      this.titleAreaClass = `titleArea${this.id}`
      this.loadDialog(value)
    },
    closeForm() {
      this.dialog = false
    },
    clearDialogData() {
      this.mainImg = ''
      this.item = {
        description: {
          item_desc: '',
        },
        overview: {
          item_overview: '',
        },
      }
      this.imgExist = false
    },

    resizeImg() {
      if (this.imgExist) {
        this.imgHeight = document.querySelector(`.${this.mainImgClass}`).offsetHeight
        if (this.$vuetify.breakpoint.name === 'xs') this.titleFontSize = 125
        if (this.$vuetify.breakpoint.name === 'sm') {
          this.titleFontSize = 150
        } else {
          this.titleFontSize = 175
        }
        document.querySelector(`.${this.titleAreaClass}`).style = `font-size:${this.titleFontSize}%;`
        const titleHeight = document.querySelector(`.${this.titleAreaClass}`).offsetHeight
        this.overviewHeight = this.imgHeight - titleHeight - 12
        const imgWidth = document.querySelector(`.${this.mainImgClass}`).offsetWidth
        this.overviewMaxWidth = imgWidth
      }
    },
    loadImg() {
      this.imgExist = true
      this.resizeImg()
    },

    loadDialog(value) {
      this.setAbsoluteOverlayStatus(true)
      this.loadItem(value).then(() => {
        this.item = {
          ...this.selectedItem,
        }
        if (this.item.images) {
          this.mainImg = `${this.item.imgUrl_prefix}/${this.item.images.split('"')[1].replace(/^\//g, '')}`
        } else {
          this.mainImg = require('@/assets/images0116/pc/221122_bimistock_image_12l.png')
        }

        this.setAbsoluteOverlayStatus(false)
      })
    },

    loadData() {
      this.setOverlayStatus(true)

      // Promise.all([this.loadSet(this.$route.params.id), this.loadSetItems(this.$route.params.id)])
      this.loadSet(this.$route.params.id).then(() => {
        this.setForm = {
          ...this.selectedSet,
        }
        if (this.setForm.set_images !== null) {
          this.setForm.images = `${this.setForm.imgUrl_prefix}/${this.setForm.set_images
            .split('"')[1]
            .replace(/^\//g, '')}`
        } else {
          this.setForm.images = require('@/assets/images0116/pc/221122_bimistock_image_12l.png')
        }
        this.setForm.set_name = this.setForm.set_name.replace(/\s/g, '\n')

        // loadSetItems
        const Items = this.setForm.details
        this.setForm.details.forEach((il, i) => {
          if (il.images) {
            Items[i].image = `${il.imgUrl_prefix}/${il.images.replaceAll('"', '').split(',')[0].replace(/^\//g, '')}`
          }
        })
        this.mobileList = [...Items]
        this.mobileList.forEach((il, i) => {
          if (il.item_name) {
            this.mobileList[i].item_name = this.mobileList[i].item_name.replace(/\s/g, '\n')
          }
        })
        const showStyle = this.setForm.show_style
        if (showStyle) {
          const len = showStyle.length
          for (let i = 0; i < len; i += 1) {
            this.setList.push(Items.splice(0, showStyle[i]))
          }
        } else {
          this.setList.push(Items)
        }
      }).catch(() => {
        console.log('Can not find the set:', this.$route.params.id)
        this.$router.push('/notexists')
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },
    addSet() {
      const Form = {
      }
      Form.product_code = this.setForm.set_code
      if (this.setForm.set_images !== null) {
        Form.load = `${this.setForm.imgUrl_prefix}/${this.setForm.set_images.split('"')[1].replace(/^\//g, '')}`
      } else {
        Form.load = require('@/assets/images/logos/NoImage.png')
      }
      Form.name = this.selectedSet.set_name
      Form.price_normal = this.setForm.set_tip
      Form.count = this.number
      this.addProduct(Form)
      this.increNavCount(this.number)
      this.$router.push('/cart')
    },
    addToCart() {
      const index = this.cartList.findIndex(obj => obj.product_code === this.setForm.set_code)
      console.log('index', index, this.cartList)
      if (index !== -1) {
        const countCheck = this.cartList[index].count
        if (countCheck === 20) {
          this.Tip = true
        } else {
          this.addSet()
        }
      } else {
        this.addSet()
      }
    },
  },
}
</script>

<style scoped>
.shippingfee {
  color: #ff6e40;
}
.shippingfee :hover {
  cursor: pointer;
}
.text {
  word-break: keep-all;
  margin-top: 8px;
  line-height: 20px;
  text-align: left;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: black;
}

@media screen and (max-width: 721px) {
  .text {
    word-break: break-all;
  }
}
.pointer :hover {
  cursor: pointer;
}

#mainImg {
  width: 100%;
  object-fit: contain;
}
#titleArea {
  font-weight: 900;
  font-family: 'Noto Sans JP', sans-serif;
}
.desc {
  font-family: 'Noto Sans JP', sans-serif;
  line-height: 45px;
}
.itemOverview >>> p {
  height: 100%;
  margin-bottom: 0;
}
.itemOverview >>> img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.v-toolbar >>> .v-toolbar__content {
  width: 100% !important;
}
</style>

<template>
  <v-dialog :value="showDialog" persistent width="1100px">
    <v-stepper v-model="el">
      <v-stepper-header>
        <v-stepper-step :complete="el > 1" step="1" color="#ff6e40">
          <span>お届け先を選択</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="el > 2" step="2" color="#ff6e40">
          <span>支払い情報</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="el > 3" step="3" color="#ff6e40">
          <span>商品と配送情報を確認</span>
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card outlined color="rgb(0,0,0,0)" class="px-2">
            <AddressStepperContent
              :el="el"
              :show-dialog="showDialog"
              :order-set="orderSet"
              :info="info"
              :shipping="shipping"
              @getShippingFee="getShippingFee"
              @upinfo="upinfo"
              @nextPage="elChange"
              @closeDialog="closeDialog"
            />
          </v-card>
        </v-stepper-content>
        <PaymentStepperContent
          :step="2"
          :card-token="cardToken"
          :is-show="el === 2"
          @sendToken="sendToken"
          @prevPage="prevPage"
          @nextPage="nextPage"
        ></PaymentStepperContent>
        <Check
          :step="3"
          :info="info"
          :order-set="orderSet"
          :card-token="cardToken"
          :shipping="shipping"
          @getShippingFee="getShippingFee"
          @prevPage="prevPage"
        />
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>
<script>
import AddressStepperContent from './Address.vue'
import PaymentStepperContent from './Payment.vue'
import Check from './Check.vue'

export default {
  components: {
    AddressStepperContent,
    PaymentStepperContent,
    Check,
  },
  props: ['showDialog', 'orderSet'],
  data: () => ({
    el: 1,
    info: '',
    cardToken: '',
    shipping: '',
  }),
  created() {
    this.loadGMO()
  },
  methods: {
    loadGMO() {
      if (document.getElementById('gmo_multipayment')) {
        return
      }
      const script = document.createElement('script')

      // script.src = 'https://static.mul-pay.jp/ext/js/token.js'
      script.src = process.env.VUE_APP_GMO_TOKEN_ENDPOINT

      script.id = 'gmo_multipayment'
      script.async = true
      script.onload = () => {
        // eslint-disable-next-line no-undef
        Multipayment.init(process.env.VUE_APP_GMO_SHOP_ID)
      }
      document.body.appendChild(script)
    },
    getShippingFee(value) {
      this.shipping = value
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    upinfo(value) {
      this.info = value
    },
    prevPage(page) {
      this.el = page > 1 ? page - 1 : page
    },
    nextPage(page) {
      this.el = page < 3 ? page + 1 : page
    },
    elChange(value) {
      this.el = value
    },
    sendToken(value) {
      this.cardToken = value
    },
  },
}
</script>

<template>
  <v-stepper-content :step="step">
    <v-form class="mt-6">
      <Card
        ref="credit_card"
        :is-card-open="true"
        :card-detail="{}"
        :action="'create'"
        @submiting="handleSubmitting()"
        @sendToken="receiveToken"
      />
      <v-row class="my-6">
        <v-spacer></v-spacer>
        <v-col cols="6" sm="3" class="d-flex justify-end">
          <v-btn outlined width="100%" @click="prevPage">
            <span>戻る</span>
          </v-btn>
        </v-col>
        <v-col cols="6" sm="3" class="d-flex justify-end">
          <v-btn color="#ff6e40" width="100%" depressed :loading="submitLoading" @click="nextPage">
            <span style="color: #fff">次へ</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-stepper-content>
</template>
<script>
import {
  ref,
} from 'vue'

import Card from '../../payment/components/Card.vue'

export default {
  components: {
    Card,
  },
  props: ['step', 'cardToken', 'isShow'],
  setup() {
    const submitLoading = ref(false)

    return {

      submitLoading,

    }
  },
  data: () => ({
  }),

  watch: {
    isShow(val) {
      if (val) {
        this.$refs.credit_card.resetValidation()
      }
    },
  },

  methods: {
    setLoading(val) {
      this.submitLoading = val
    },
    prevPage() {
      this.$emit('prevPage', this.step)
      this.$refs.credit_card.resetValidation()
    },
    nextPage() {
      this.setLoading(true)
      console.log(this.$refs.credit_card)
      this.$refs.credit_card.submitCard()
    },
    receiveToken(card) {
      console.log('receive-token', card)
      if (card !== null) {
        this.$emit('sendToken', card.token)
        this.$emit('nextPage', this.step)
      }
      this.submitLoading = false
    },
  },
}
</script>
